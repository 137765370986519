/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import ThreeScene from "../ThreeScene"

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=613020fcf57f7882236a2135").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class IndexView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('..\controllers/IndexController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = IndexView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '613020fdf57f78728f6a213b'
    htmlEl.dataset['wfSite'] = '613020fcf57f7882236a2135'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = IndexView.Controller !== IndexView ? transformProxies(this.props.children) : {

    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(..\\css\\normalize.css);
          @import url(..\\css\\webflow.css);
          @import url(..\\css\\supstop.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-body">
            <div className="af-class-loading_screen"> </div>
            <div data-w-id="f1e177de-8eb8-771e-0ef2-1d24bb237221" className="af-class-opening-overlay">
              <div data-w-id="975d4dcc-7f1a-c621-00f0-76d5d85527ea" className="af-class-arrow_indicator" style={{display: "none"}}>
                <div className="af-class-flex_arrow"><img src="images/arrow-down.png" alt className="af-class-image-5" /></div>
              </div>
            </div>
            <div className="af-class-homepage-content">
              <div className="af-class-nav-bar">
                <div className="af-class-nav-left">
                  <a href="shop.html" className="af-class-nav-link">Fragrances</a>
                  <a href="beliefs.html" className="af-class-nav-link">Ingredients</a>
                </div>
                <a href="emvy.html" className="af-class-emvy-logo">CAETANO</a>
                <div className="af-class-nav-right">
                  <a href="values.html" className="af-class-nav-link">About</a>
                  <form action="/search" className="af-class-search w-form"><input type="search" className="af-class-search-input w-input" maxLength={256} name="query" placeholder="Search…" id="search" required /><input type="submit" defaultValue="Search" className="af-class-search-button w-button" /></form>
                </div>
              </div>
              <div className="w-layout-grid af-class-homepage-product-grid">
                <div id="w-node-b371ab94-3365-a94d-240e-12494837def4-8f6a213b" className="af-class-product-home_div">
                  <ThreeScene />
                  <div data-w-id="e70f23e6-f335-e119-1c4e-1eb392664824" className="af-class-product-info">
                    <a href="shop.html" className="af-class-link-to-product w-inline-block" />
                    <div className="af-class-product-headings">
                      <div className="af-class-product-headings">
                        <div className="af-class-product-content-top">
                          <h1 className="af-class-h3">CAETANO</h1>
                          <div className="af-class-spacer" />
                          <p className="af-class-body-text af-class-bold">Le Legend Myseriouse</p>
                          <div className="af-class-spacer" />
                          <h1 className="af-class-h3 af-class-price">$65.00</h1>
                        </div>
                      </div>
                    </div>
                    <div className="af-class-product-content-bottom">
                      <div className="af-class-product-headings af-class-bottom">
                        <div className="af-class-product-content-top af-class-bottom">
                          <p className="af-class-body-text">Smokey and mysterious fragrance that will keep them guessing.</p>
                        </div>
                        <div className="af-class-arrow"><img src="images/Arrow-right.svg" data-w-id="e70f23e6-f335-e119-1c4e-1eb392664838" alt className="af-class-image af-class-_1" /></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="af-class-product-image_div"><img src="images/man-standing-fog-evening-night-time-small.jpg" loading="lazy" sizes="(max-width: 2508px) 100vw, 2508px" srcSet="images/man-standing-fog-evening-night-time-small-p-1080.jpeg 1080w, images/man-standing-fog-evening-night-time-small-p-1600.jpeg 1600w, images/man-standing-fog-evening-night-time-small-p-2000.jpeg 2000w, images/man-standing-fog-evening-night-time-small.jpg 2508w" alt className="af-class-image-8" /></div>
              </div>
              <div className="w-layout-grid af-class-homepage-product-grid af-class-reversed">
                <div className="af-class-product-image_div"><img src="images/curry-powder-spices-wooden-cup-with-black-background-industry-concept-small.jpg" loading="lazy" sizes="(max-width: 2508px) 100vw, 2508px" srcSet="images/curry-powder-spices-wooden-cup-with-black-background-industry-concept-small-p-500.jpeg 500w, images/curry-powder-spices-wooden-cup-with-black-background-industry-concept-small-p-1080.jpeg 1080w, images/curry-powder-spices-wooden-cup-with-black-background-industry-concept-small-p-1600.jpeg 1600w, images/curry-powder-spices-wooden-cup-with-black-background-industry-concept-small-p-2000.jpeg 2000w, images/curry-powder-spices-wooden-cup-with-black-background-industry-concept-small.jpg 2508w" alt className="af-class-image-9" /></div>
                <div id="w-node-_1cdf8f0b-ed14-59d3-b18e-fc20712d8291-8f6a213b" className="af-class-product-home_div">
                  <div data-w-id="1cdf8f0b-ed14-59d3-b18e-fc20712d8292" className="af-class-product-info">
                    <a href="shop.html" className="af-class-link-to-product w-inline-block" />
                    <div className="af-class-product-headings">
                      <div className="af-class-product-headings">
                        <div className="af-class-product-content-top">
                          <h1 className="af-class-h3">CAETANO</h1>
                          <div className="af-class-spacer" />
                          <p className="af-class-body-text af-class-bold">Cinnaman</p>
                          <div className="af-class-spacer" />
                          <h1 className="af-class-h3 af-class-price">$65.00</h1>
                        </div>
                      </div>
                    </div>
                    <div className="af-class-product-content-bottom">
                      <div className="af-class-product-headings af-class-bottom">
                        <div className="af-class-product-content-top af-class-bottom">
                          <p className="af-class-body-text">Spicy aroma with a dash of exploration for the man who knows what he wants.</p>
                        </div>
                        <div className="af-class-arrow"><img src="images/Arrow-right.svg" alt className="af-class-image af-class-_1" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-layout-grid af-class-homepage-product-grid">
                <div id="w-node-_83fd7404-3447-0379-6da7-f69965a96b56-8f6a213b" className="af-class-product-home_div">
                  <div data-w-id="83fd7404-3447-0379-6da7-f69965a96b57" className="af-class-product-info">
                    <a href="shop.html" className="af-class-link-to-product w-inline-block" />
                    <div className="af-class-product-headings">
                      <div className="af-class-product-headings">
                        <div className="af-class-product-content-top">
                          <h1 className="af-class-h3">CAETANO</h1>
                          <div className="af-class-spacer" />
                          <p className="af-class-body-text af-class-bold">Adventure</p>
                          <div className="af-class-spacer" />
                          <h1 className="af-class-h3 af-class-price">$65.00</h1>
                        </div>
                      </div>
                    </div>
                    <div className="af-class-product-content-bottom">
                      <div className="af-class-product-headings af-class-bottom">
                        <div className="af-class-product-content-top af-class-bottom">
                          <p className="af-class-body-text">A fresh and adventurous scent coupled with a whiff of leathery goodness.</p>
                        </div>
                        <div className="af-class-arrow"><img src="images/Arrow-right.svg" alt className="af-class-image af-class-_1" /></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="af-class-product-image_div"><img src="images/river-foggy-mountains-landscape-smaller.jpg" loading="lazy" sizes="100vw" srcSet="images/river-foggy-mountains-landscape-smaller-p-500.jpeg 500w, images/river-foggy-mountains-landscape-smaller-p-1080.jpeg 1080w, images/river-foggy-mountains-landscape-smaller-p-1600.jpeg 1600w, images/river-foggy-mountains-landscape-smaller-p-2000.jpeg 2000w, images/river-foggy-mountains-landscape-smaller.jpg 2508w" alt className="af-class-image-6" /></div>
              </div>
              <div className="w-layout-grid af-class-homepage-product-grid af-class-reversed">
                <div className="af-class-product-image_div"><img src="images/adventure-freedom-small.jpg" loading="lazy" sizes="(max-width: 2508px) 100vw, 2508px" srcSet="images/adventure-freedom-small-p-500.jpeg 500w, images/adventure-freedom-small-p-1080.jpeg 1080w, images/adventure-freedom-small-p-1600.jpeg 1600w, images/adventure-freedom-small-p-2000.jpeg 2000w, images/adventure-freedom-small.jpg 2508w" alt className="af-class-image-10" /></div>
                <div id="w-node-_1bb98856-efab-1e5a-cf04-cc8cd9c9659c-8f6a213b" className="af-class-product-home_div">
                  <div data-w-id="1bb98856-efab-1e5a-cf04-cc8cd9c9659d" className="af-class-product-info">
                    <a href="shop.html" className="af-class-link-to-product w-inline-block" />
                    <div className="af-class-product-headings">
                      <div className="af-class-product-headings">
                        <div className="af-class-product-content-top">
                          <h1 className="af-class-h3">CAETANO</h1>
                          <div className="af-class-spacer" />
                          <p className="af-class-body-text af-class-bold">Freedom</p>
                          <div className="af-class-spacer" />
                          <h1 className="af-class-h3 af-class-price">$65.00</h1>
                        </div>
                      </div>
                    </div>
                    <div className="af-class-product-content-bottom">
                      <div className="af-class-product-headings af-class-bottom">
                        <div className="af-class-product-content-top af-class-bottom">
                          <p className="af-class-body-text">The smell of accomplishment, feel surrounded by nature and hit by a fresh breeze.</p>
                        </div>
                        <div className="af-class-arrow"><img src="images/Arrow-right.svg" alt className="af-class-image af-class-_1" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-footer">
              <h1 className="af-class-h3">CAETANO</h1>
              <div className="af-class-spacer af-class-medium" />
              <div className="af-class-flex_footer-content">
                <div id="w-node-e6273b04-f5cf-7cc1-d51c-c15500b2c81f-00b2c81a" className="af-class-product-headings">
                  <div className="af-class-product-content-top">
                    <p className="af-class-body-text af-class-bold">User Guide</p>
                    <div className="af-class-spacer" />
                    <a href="#" className="af-class-body-text af-class-space-below">Occasions</a>
                    <a href="#" className="af-class-body-text af-class-space-below">FAQS</a>
                  </div>
                </div>
                <div id="w-node-e6273b04-f5cf-7cc1-d51c-c15500b2c82a-00b2c81a" className="af-class-product-content-top">
                  <p className="af-class-body-text af-class-bold">Sign Up</p>
                  <div className="af-class-spacer" />
                  <div className="w-form">
                    <form id="email-form" name="email-form" data-name="Email Form" className="af-class-form"><input type="email" className="af-class-text-field w-input" maxLength={256} name="email-2" data-name="Email 2" placeholder="Sign up - you wont regret it" id="email-2" required /><input type="submit" defaultValue="Submit" data-wait="Please wait..." className="af-class-submit-button w-button" /></form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div id="w-node-e6273b04-f5cf-7cc1-d51c-c15500b2c838-00b2c81a" className="af-class-product-content-top">
                  <p className="af-class-body-text af-class-bold">Quick links</p>
                  <div className="af-class-spacer" />
                  <a href="shop.html" className="af-class-body-text af-class-space-below">Shop&nbsp;All</a>
                  <a href="beliefs.html" className="af-class-body-text af-class-space-below">Ingredients</a>
                  <a href="values.html" className="af-class-body-text af-class-space-below">About</a>
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default IndexView

/* eslint-enable */